import {isValue} from '../is'

const
    transition = value => (
        isValue(value) ?
            {
                transition: (
                    value === true ?
                        'all 0.25s ease' :
                        value)
            } :
            {})

export {transition}
