import {any, each, filter, map} from '@republic/foundation/lang/array'
import {noop} from '@republic/foundation/lang/function'
import {createNamed} from '@republic/foundation/storage'
import session from '@dash/core/services/storage/session'
import env from '@dash/env'
import {error} from '@dash/logging/log'
import {prefix, version, name} from './name'
import Pool from './pool'
import subscribe from './subscribe'

const
    read = new Pool('read').request,
    write = new Pool('write').request,
    cache = createNamed(session, `${env.version}:model:subscriber`),

    decorate = config => {
        const
            subscriber = cache.get()?.subscriber || '',
            db = name(subscriber)

        return (
            subscriber ?
                config.method === 'get' ?
                    read({db, ...config}) :
                    config.method === 'subscribe' ?
                        subscribe({db, ...config}) :
                        write({db, ...config}) :
                config.method === 'subscribe' ?
                    noop :
                    Promise.reject(new Error('no-subscriber-id')))
    },

    get = config => decorate(({method: 'get', ...config})),
    live = config => decorate(({method: 'subscribe', ...config})),
    clear = table => decorate({method: 'clear', table}),
    put = (table, data) => decorate({method: 'put', table, data}),
    update = (table, id, data) => decorate({method: 'update', table, id, data}),
    remove = (table, id) => decorate({method: 'remove', table, id}),
    destroy = () => decorate({method: 'destroy'}),

    // remove older versions of Dash db
    prune = hard => {
        const prefixes = [prefix, '_pouch_dash_v', '_pouch_internal']

        if (window.indexedDB && window.indexedDB.databases) {
            window.indexedDB.databases()
            .then(dbs => {
                const
                    names = map(dbs, ({name}) => name),
                    candidates = (
                        filter(
                            names,
                            dbName => (
                                (hard || !dbName.startsWith(version)) &&
                                any(prefixes, prefix => dbName.startsWith(prefix)))))

                each(
                    candidates,
                    name => window.indexedDB.deleteDatabase(name))
            })
            .catch(error)
        }
    }

prune()
export {
    clear,
    destroy,
    get,
    live,
    prune,
    put,
    remove,
    update
}