import {isEqual} from '@republic/foundation/lang/is'
import {model} from './model'

const
    authenticating = (
        model.map(({csrf, state, verifier}) => ({
            csrf,
            enabled: state === 'authenticating',
            verifier
        }))
        .unique(isEqual)
        .latest()),

    authenticated = (
        model.map(({state}) => state === 'authenticated' || state === 'validating')
        .unique(isEqual)
        .latest()),

    unauthenticating = (
        model
        .map(({state}) => state === 'unauthenticating')
        .unique(isEqual)
        .latest()),

    unauthenticated = (
        model.map(({state}) => state === 'unauthenticated')
        .unique(isEqual)
        .latest()),

    token = (
        model
        .map(({token}) => token || null)
        .unique(isEqual)
        .latest()),

    info = (
        model.map(({info}) => info)
        .unique(isEqual)
        .latest()),

    user = (
        model
        .map(
            ({info}) => (
                info && info.user ?
                    info.user :
                    null))
        .unique(isEqual)
        .latest())

export {
    authenticating,
    authenticated,
    unauthenticating,
    unauthenticated,
    info,
    user,
    token
}
