import React from 'react'
import {clean} from '../../core/services/cleanup'
import {error} from '../../logging/log'
import Button from './Button'
import Center from './Center'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {hasError: false}
    }

    static getDerivedStateFromError() {
        return {hasError: true}
    }

    componentDidCatch(err, info) {
        const message = err.message

        if (message.toLowerCase().includes('loading chunk')) {
            error(new Error('Chunk loading error'), err.message)
        } else {
            error(err, info)
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <Center>
                    <Button
                        size={'xl'}
                        label={'Something unexpected happened. Please reload Dash.'} />
                    <Button
                        outline
                        margin={{top: 1}}
                        action={() => {
                            clean()
                            .then(() => window.location.reload())
                        }}
                        label={'Reload'} />
                </Center>
            )
        } else {
            return this.props.children
        }
    }
}

export default ErrorBoundary
