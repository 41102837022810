import {useState, useEffect} from 'react'
import {delayed} from '@republic/foundation/browser/execution'
import {isNumber} from '@republic/foundation/lang/is'

const
    useThrottle = (fn, throttle) => {
        const
            [throttled, setThrottled] = useState(false),
            onClick = (...args) => {
                if (fn) {
                    if (!throttled) {
                        fn(...args)
                    }
                    if (throttle && !throttled) {
                        setThrottled(true)
                    }
                }
            }

        useEffect(
            () => {
                if (throttle && throttled) {
                    return (
                        delayed(
                            () => setThrottled(false),
                            isNumber(throttle) ?
                                throttle :
                                2500))
                }
            },
            [throttle, throttled])

        return {onClick, throttled}
    }

export default useThrottle
