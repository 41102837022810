import {title, trim} from '@republic/foundation/lang/string'

const
    fromSnakeToTitle = text => (
        title(trim(text || '').replace(/_/g, ' ').toLowerCase())),

    fromTitleToSnake = text => (
        trim(text || '').replace(/\s/g, '_').toLowerCase()),

    pretty = slug => (
        title(trim(slug || '').split(':').join(' '))),

    slugify = name => (
        pretty(name).toLowerCase().split(' ').join('')),

    truncate = (string, chars = 24) => (
        string.length > chars ?
            string.slice(0, chars) + '…' :
            string),

    toHex = value => {
        const
            head = value.slice(0, 2).toUpperCase(),
            rest = value.slice(2)

        return (
            rest.length ?
                `${head}:` + toHex(rest) :
                head)
    },

    toArray = str => str.split(''),

    upper = value => (value || '').toUpperCase(),
    lower = value => (value || '').toLowerCase(),

    plural = (word, count) => (
        count === 1 ?
            word :
            `${word}s`)

export {
    toArray,
    fromSnakeToTitle,
    fromTitleToSnake,
    plural,
    pretty,
    slugify,
    truncate,
    toHex,
    upper,
    lower
}
