import {createMethod} from '@republic/foundation/streams/models'

const
    methods = {
        initialize: createMethod(),
        login: {
            begin: createMethod(),
            authenticating: createMethod((code, verifier) => ({code, verifier})),
            authenticated: createMethod(({token, info}) => ({token, info}))
        },
        logout: {
            begin: createMethod(redirect => ({redirect})),
            unauthenticating: createMethod(),
            unauthenticated: createMethod()
        },
        dump: createMethod()
    }

export {methods}
