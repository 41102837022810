import {last, reduce} from '@republic/foundation/lang/array'
import {update as collection} from '@republic/foundation/lang/collection'
import {isArray} from '@republic/foundation/lang/is'
import {owns} from '@republic/foundation/lang/object'
import {butlast} from './array'

const
    update = (obj, ...rest) => {
        const
            path = butlast(rest),
            value = last(rest)

        return collection(obj, ...path)(value)
    },

    pick = (object, key) => (
        reduce(
            isArray(key) ? key : [key],
            (memo, item) => (
                owns(object, item) ?
                    {...memo, [item]: object[item]} :
                    memo),
            {})),

    omit = (object, key) => (
        isArray(key) ?
            reduce(
                key,
                (memo, item) => (
                    omit(memo, item)),
                object) :
        key && owns(object, key) ?
            (({[key]: _, ...object}) => object)(object) :
            object)

export {pick, omit, update}
