import {sorted} from '@republic/foundation/lang/array'
import {get} from '@republic/foundation/lang/object'

const
    sort = (
        (list, key, ascending) => (
            sorted(
                list,
                (left, right) => {
                    const
                        a = key ? get(left, ...key.split('.')) : left,
                        b = key ? get(right, ...key.split('.')) : right

                    return (
                        !b && !a ?
                            0 :
                            !b ?
                                -1 :
                                !a ?
                                    1 :
                                    ascending ?
                                        String(b).localeCompare(String(a), undefined, {numeric: true}) :
                                        String(a).localeCompare(String(b), undefined, {numeric: true})
                    )
                })))

export {sort}
