import React, {memo, useEffect, useState, useMemo} from 'react'
import hoist from 'hoist-non-react-statics'
import {isEqual, isFunction} from '@republic/foundation/lang/is'
import {Source, Stream} from '@republic/foundation/streams'
import {createComponent} from '@dash/core/services/component'

const
    name = WrappedComponent => (
        `Subscribe(${
            WrappedComponent.displayName ||
            WrappedComponent.name ||
            'Component'
        })`),

    createEnhancer = (fn, exclude) => (
        WrappedComponent => (
            hoist(
                fn(WrappedComponent),
                WrappedComponent,
                exclude))),

    subscribe = (selector, combiner, propMerge) => (
        createEnhancer(
            WrappedComponent => (
                memo(
                    createComponent(
                        name(WrappedComponent),
                        {},
                        ({children, ...props}) => {
                            const
                                [state, setState] = useState(void 0),
                                source = useMemo(() => new Source(), []),
                                select = isFunction(selector) ? selector : () => null

                            useEffect(
                                () => (
                                    Stream.combineAll([
                                        source
                                        .map(select)
                                        .unique(isEqual)
                                        .map(combiner)
                                        .select()
                                        .unique(isEqual),

                                        source
                                        .unique(isEqual)
                                    ])
                                    .map(([state, props]) => propMerge(state, props))
                                    .unique(isEqual)
                                    .subscribe(state => setState(state))),
                                [])

                            useEffect(
                                () => {
                                    source.next(props)
                                },
                                [props])

                            return (
                                state ?
                                    <WrappedComponent {...{
                                        ...state,
                                        children
                                    }} /> :
                                    null)
                        })))))

export default subscribe