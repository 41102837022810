import {omit} from '@dash/core/services/object'
import Worker from './database-worker'

const
    handler = (message, cb, eb) => {
        const {data, error} = message.data

        if (error) {
            eb(error)
        } else {
            cb(data)
        }
    },

    request = config => {
        const
            worker = new Worker(),
            message = payload => (
                handler(payload, config.cb, config.eb))

        worker.addEventListener('message', message)
        worker.postMessage(omit(config, ['cb', 'eb']))
        return () => {
            worker.postMessage({method: 'unsubscribe'})
            worker.removeEventListener('message', message)
            worker.terminate()
        }
    }

export default request