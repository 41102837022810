import React, {forwardRef, createElement, useEffect} from 'react'
import {keys} from '@republic/foundation/lang/object'
import {createComponent} from '@dash/core/services/component'
import {omit} from '../core/services/object'
import {create} from './utils/style-manager'
import {color} from './utils/styles/background-color'
import {border} from './utils/styles/border'
import {radius} from './utils/styles/border-radius'
import {shadow} from './utils/styles/box-shadow'
import {cursor} from './utils/styles/cursor'
import {dimensions} from './utils/styles/dimensions'
import {display} from './utils/styles/display'
import {margin} from './utils/styles/margin'
import {opacity} from './utils/styles/opacity'
import {overflow} from './utils/styles/overflow'
import {padding} from './utils/styles/padding'
import {position} from './utils/styles/position'
import {positions} from './utils/styles/positions'
import {transition} from './utils/styles/transition'
import {layer} from './utils/styles/z-index'

const
    types = {
        className: true,
        tag: true,
        children: true,

        margin: true,
        padding: true,

        width: true,
        height: true,
        maxWidth: true,
        maxHeight: true,
        minWidth: true,
        minHeight: true,

        layer: true,
        position: true,
        positions: true,

        border: true,
        color: true,
        cursor: true,
        display: true,
        opacity: true,
        overflow: true,
        radius: true,
        shadow: true,
        transition: true
    },

    Particle = (
        createComponent(
            'Particle',
            {},
            ({forwardRef, children, tag, ...props}) => {
                const
                    {destroy, hash, style} = (
                        create(
                            'bits-particle',
                            props,
                            {
                                ...margin(props.margin),
                                ...padding(props.padding),

                                ...{boxSizing: 'border-box'},
                                ...dimensions('width', props.width),
                                ...dimensions('height', props.height),
                                ...dimensions('maxWidth', props.maxWidth),
                                ...dimensions('maxHeight', props.maxHeight),
                                ...dimensions('minWidth', props.minWidth),
                                ...dimensions('minHeight', props.minHeight),

                                ...layer(props.layer),
                                ...position(props.position),
                                ...positions(props.positions),

                                ...border(props.border),
                                ...color(props.color),
                                ...cursor(props.cursor),
                                ...display(props.display),
                                ...opacity(props.opacity),
                                ...overflow(props.overflow),
                                ...radius(props.radius),
                                ...shadow(props.shadow),
                                ...transition(props.transition)
                            }))

                useEffect(
                    () => () => destroy(hash),
                    [hash])

                return (
                    createElement(
                        tag || 'div',
                        {
                            ref: forwardRef,
                            ...omit(props, keys(types)),
                            ...style,
                        },
                        children))
            }))

export default forwardRef((props, ref) => (
    <Particle forwardRef={ref} {...props} />
))
