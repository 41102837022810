import {Source} from '@republic/foundation/streams'
import {noop} from '@republic/foundation/lang/function'

const
    source = new Source(true),
    update = () => source.next(!document.hidden),
    focus = source.latest()

if (typeof document.hidden !== 'undefined') {
    document.addEventListener('visibilitychange', update)
} else {
    document.addEventListener('focus', update)
}

focus.subscribe(noop)
update()

export {focus}
