import {subscribe} from '@republic/bits'
import {unauthenticated} from '../streams'

export default (
    subscribe(
        null,
        () => unauthenticated,
        (unauthenticated, props) => ({
            ...props,
            unauthenticated
        })))
