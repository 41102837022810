import env from '@dash/env'

const
    prefix = `dash-db-`,
    version = `${prefix}${env.version}`,
    name = subscriber => `${version}-${subscriber}`

export {
    prefix,
    version,
    name
}