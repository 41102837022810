import React from 'react'
import {createComponent} from '@dash/core/services/component'
import Button from '../../core/components/Button'
import SyncIcon from '../../core/components/SyncIcon'
import unauthenticated from '../enhancers/unauthenticated'
import {methods} from '../methods'

export default (
    unauthenticated(
        createComponent(
            'LoginButton',
            {},
            ({unauthenticated}) => (
                <Button
                    outline
                    size={'s'}
                    disabled={!unauthenticated}
                    action={methods.login.begin}
                    label={unauthenticated ? 'Sign In' : 'Loading'}
                    rotate={!unauthenticated}
                    Icon={!unauthenticated ? SyncIcon : null} />
            ))))
