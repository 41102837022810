import {parse} from '@republic/foundation/http/urls'
import track from '../../analytics/services/track'
import env from '../../env'

const
    format = url => {
        const
            {pathname} = parse(url),
            [segments] = pathname.split('?')

        if (pathname.startsWith(env.ibot.path)) {
            return {
                type: 'ibot',
                resource: segments.split('/')[2]
            }
        }

        else if (pathname.startsWith(env.stratus.v3)) {
            return {
                type: 'stratus',
                resource: segments.split('/')[6]
            }
        }

        else if (pathname.startsWith(env.auth.realm)) {
            return {
                type: 'auth',
                resource: segments.split('/')[2]
            }
        }

        else if (pathname.includes('generate_204')) {
            return {
                type: 'ibot',
                resource: 'nocell'
            }
        }

        else {
            return {
                type: 'unknown',
                resource: pathname
            }
        }
    },

    performance = request => {
        const start = window.performance.now()

        return status => {
            const
                stop = window.performance.now(),
                payload = {
                    ...format(request.url),
                    method: request.method,
                    status,
                    timing: (stop - start).toFixed(0),
                    focused: !document.hidden
                }

            // ignore network errors by requiring a status
            if (status && payload.type) {
                track('performance', payload)
            }
        }
    }

export default performance
