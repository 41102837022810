import {filter, index, reduce} from '@republic/foundation/lang/array'
import {isArray} from '@republic/foundation/lang/is'

const
    head = ([head]) => head,

    tail = ([_head, ...tail]) => tail,

    butlast = list => list.slice(0, -1),

    find = (list, fn) => list.find(fn),

    some = (list, fn) => (
        list.some(fn)),

    every = (list, fn) => (
        list.every(fn)),

    owns = (list, value) => (
        index(list, item => item === value) >= 0),

    mirror = list => (
        reduce(
            list,
            (memo, key) => {
                memo[key] = key
                return memo
            },
            {})),

    range = (start, end) => (
        reduce(
            new Array((end + 1) - start),
            (memo, _, i) => ([
                ...memo,
                start + i
            ]),
            [])),

    toString = array => array.join(''),

    flatten = array => (
        reduce(
            array,
            (memo, value) => {
                if (isArray(value)) {
                    memo = [...memo, ...value]
                } else {
                    memo = [...memo, value]
                }
                return memo
            },
            [])),

    diff = (prev, next) => ({
        added: filter(next, id => !owns(prev, id)),
        removed: filter(prev, id => !owns(next, id)),
        same: filter(next, id => owns(prev, id))
    }),

    keyOrdered = (orderedKeys, items, itemKey = 'id') => {
        const
            ordered = (
                reduce(
                    orderedKeys,
                    (memo, key) => {
                        const item = find(items, item => item[itemKey] === key)

                        return item ? [...memo, item] : memo
                    },
                    [])),
            rest = (
                reduce(
                    items,
                    (memo, item) => (
                        !owns(orderedKeys, item[itemKey]) ?
                            [...memo, item] :
                            memo),
                    []))

        return [...ordered, ...rest]
    },

    toObject = (list, key) => (
        reduce(
            list,
            (memo, item) => ({
                ...memo,
                [item[key]]: item
            }),
            {}))

export {
    butlast,
    diff,
    find,
    flatten,
    head,
    keyOrdered,
    mirror,
    owns,
    tail,
    range,
    some,
    every,
    toString,
    toObject
}
