import {encrypt} from 'crypto-js/aes'
import hash from '@dash/env/hash'
import mix from './mix'

const
    pack = message => (
        encrypt(
            JSON.stringify(message),
            mix(hash))
        .toString())

export default pack