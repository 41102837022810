import {isDefined, isFunction} from '@republic/foundation/lang/is'
import {Stream} from '@republic/foundation/streams'

const
    isStream = maybeStream => maybeStream instanceof Stream,

    toPromise = maybeStream => (
        new Promise(resolve => {
            if (isStream(maybeStream)) {
                const
                    subscription = (
                        maybeStream
                        .filter(data => isDefined(data))
                        .subscribe(data => {
                            subscription()
                            resolve(data)
                        }))
            } else if (isFunction(maybeStream)) {
                resolve(maybeStream())
            } else {
                resolve(maybeStream)
            }
        }))

export {toPromise, isStream}
