import {subscribe} from '@republic/bits'
import {subscriber} from '../streams'

export default (
    subscribe(
        null,
        () => subscriber,
        (subscriber, props) => ({
            ...props,
            subscriber
        })))
