import {filter, map} from '@republic/foundation/lang/array'
import {noop} from '@republic/foundation/lang/function'
import {series} from '@dash/core/services/promise'
import {sort} from '@dash/core/services/sort'

let cleaners = []

const
    cleanup = obj => {
        cleaners = (
            sort(
                [
                    {
                        ...obj,
                        priority: obj.priority || 10
                    },
                    ...filter(
                        cleaners,
                        ({name}) => name !== obj.name)
                ],
                'priority'))
    },
    clean = type => (
        series(
            map(
                cleaners,
                ({fn}) => () => (
                    Promise.resolve(fn(type)).catch(noop))))),
    fetch = () => cleaners

export {clean, cleanup, fetch}
