import axios from 'axios'
import {delayed} from '@republic/foundation/browser/execution'
import sha from '@republic/foundation/crypto/sha1'
import {parse} from '@republic/foundation/http/urls'
import {each, filter} from '@republic/foundation/lang/array'
import {stringify} from '@republic/foundation/lang/json'
import {get} from '@republic/foundation/lang/object'
import {uuid} from '@republic/foundation/lang/random'
import track from '../../analytics/services/track'
import {methods} from '../../auth/methods'
import {cleanup} from '../../core/services/cleanup'
import {toPromise} from '../../core/services/streams'
import env from '../../env'
import {error, log} from '../../logging/log'
import {storage} from '../../mode/services/mode'
import {omit} from './object'
import performance from './performance'
import reporting from './reporting'

let mode = storage.get()

const
    LIMIT = 50,
    DEDUPE = 2000,
    requests = new Map(),

    traffic = (
        new Map([
            [env.ibot.main, 0],
            ['unmanaged', 0]
        ])),

    cooldown = key => {
        const request = requests.get(key)

        // reset cooldown timer
        if (request.cooldown) {
            request.cooldown()
        }
        request.cooldown = (
            delayed(
                () => {
                    requests.delete(key)
                },
                DEDUPE))
    },

    process = () => (
        each(
            filter(
                Array.from(requests.entries()),
                ([_, {cooldown, busy}]) => !cooldown && !busy),
            ([key, request]) => {
                const
                    isIbot = request.options.ibot || request.options.url.startsWith(env.ibot.path),
                    unmangaged = !isIbot && traffic.get('unmanaged') <= LIMIT ? 'unmanaged' : null,
                    domain = (
                        isIbot ?
                            traffic.get(env.ibot.main) <= LIMIT ?
                                env.ibot.main :
                                null :
                            unmangaged)

                if (domain) {

                    traffic.set(domain, traffic.get(domain) + 1)
                    request.busy = true

                    request.fn(isIbot ? mode ? `https://${mode}-qa-ibot.relaysvr.com` : domain : null)
                    .then(request.resolve)
                    .catch(request.reject)
                    .finally(() => {
                        const request = requests.get(key)

                        traffic.set(domain, traffic.get(domain) - 1)
                        request.busy = false
                        cooldown(key)
                        process()
                    })
                }
            })),

    breaker = (id, {method, url}, reject) => {
        const
            request = requests.get(id),
            tripped = request.tripped + 1,
            message = `Too many requests`

        // report excessive duplicates
        if (tripped === 10) {
            console.warn(message, method, url)
            error(message, {method, url})
        }
        request.tripped = tripped
        reject(new Error(message))
    },

    batch = (options, cancel, fn) => (
        new Promise((resolve, reject) => {
            const
                id = (
                    options.exempt ?
                        uuid() :
                        sha(options.method + options.url + stringify(options.data || {})))

            if (!requests.has(id)) {
                requests.set(
                    id,
                    {
                        resolve,
                        reject,
                        fn,
                        cancel,
                        busy: false,
                        tripped: 0,
                        cooldown: null,
                        options
                    })
                process()
            } else {
                breaker(id, options, reject)
            }
        })),

    createSource = () => axios.CancelToken.source(),

    auth = (token, options) => (
        import('../../subscriber/streams')
        .then(({hint}) => toPromise(hint))
        .then(hint => (
            token ?
                {
                    ...options,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        ...(hint && !options.nohint ?
                            {
                                'X-Relay-Clustering-Hint': hint
                            } :
                            {}),
                        ...(options.headers || {})
                    }
                } :
                options))),

    xhr = options => {
        const source = createSource()

        return (
            batch(
                options,
                source.cancel,
                domain => {
                    const stop = performance(options)

                    if (domain) {
                        options.url = domain + options.url
                    }

                    return (
                        axios({...options, cancelToken: source.token})
                        .then(result => {
                            const
                                status = get(result, 'status'),
                                parsed = parse(result.config.url),
                                method = result.config.method

                            stop(status)

                            log(
                                `[${method.toUpperCase()}] [${status}] ${parsed.pathname}`,
                                {
                                    status,
                                    request: omit(options, ['headers', 'cancelToken']),
                                    response: result.data
                                },
                                {category: 'request'})

                            return (
                                options.raw ?
                                    result :
                                    result.data)
                        })
                        .catch(error => {
                            const
                                status = get(error, 'response', 'status'),
                                response = get(error, 'response', 'data') || error.message,
                                parsed = parse(options.url)

                            if (status === 401) {
                                methods.dump()
                            }

                            stop(status)

                            log(
                                `[${options.method.toUpperCase()}] [${status || ''}] ${parsed.pathname}`,
                                {
                                    status,
                                    request: omit(options, 'headers', 'cancelToken'),
                                    response
                                },
                                {category: 'request', level: 'error'})

                            if (error.response) {
                                const reported = reporting(status, parsed.pathname, error.response.data)

                                track(
                                    'error',
                                    {
                                        type: 'request',
                                        status_code: error.response.status,
                                        reason: response,
                                        path: parsed.pathname,
                                        expected: !reported
                                    })
                            }

                            return Promise.reject(error)
                        }))
                }))
    },

    getter = (token, url, options = {}) => (
        auth(token, options)
        .then(options => (
            xhr({
                url,
                method: 'get',
                ...options
            })))),

    poster = (token, url, data, options = {}) => (
        auth(token, options)
        .then(options => (
            xhr({
                url,
                method: 'post',
                data,
                ...options
            })))),

    putter = (token, url, data, options = {}) => (
        auth(token, options)
        .then(options => (
            xhr({
                url,
                method: 'put',
                data,
                ...options
            })))),

    deleter = (token, url, data, options = {}) => (
        auth(token, options)
        .then(options => (
            xhr({
                url,
                method: 'delete',
                data,
                ...options
            }))))

cleanup({
    name: 'requests',
    priority: 1,
    fn: () => {
        mode = storage.get()
        requests.forEach(value => value.cancel())
    }
})

export {
    getter,
    poster,
    putter,
    deleter
}
