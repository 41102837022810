import React from 'react'
import {Layout} from '@republic/bits'
import {createComponent} from '@dash/core/services/component'

export default (
    createComponent(
        'Center',
        {},
        ({children, ...props}) => (
            <Layout
                height={'100%'}
                width={'100%'}
                align={'center'}
                justify={'center'}
                {...props}>
                {children}
            </Layout>
        )))
