import {updated} from '@republic/foundation/http/query'
import {any, map} from '@republic/foundation/lang/array'
import {info, token, user} from '../../auth/streams'
import {owns as arrayOwns} from '../../core/services/array'
import {getter} from '../../core/services/request'
import {toPromise} from '../../core/services/streams'
import env from '../../env'
import {roles} from './roles'

class ServiceError extends Error {
    constructor(message, type) {
        super(message)
        this.type = type
    }
}

const
    query = (options = {}) => (
        toPromise(token)
        .then(token => (
            token ?
                getter(
                    token,
                    updated(
                        env.stratus.v3 + 'subscribers;view=paged_dash_overview',
                        {
                            page_size: options.size || 100,
                            ...(options.email ? {owner_email: options.email} : {}),
                            ...(options.name ? {account_name: options.name} : {}),
                            ...(options.subscriber ? {subscriber_id: options.subscriber} : {})
                        }))
                .then(({members}) => (
                    map(members, member => ({
                        account_id: member.account_id,
                        subscriber: member.id,
                        name: member.name,
                        email: member.email,
                        role: member.permission_group
                    })))) :
                Promise.reject(new Error('invalid-requirements'))))),

    account = subscriber => (
        toPromise(token)
        .then(token => (
            token && subscriber ?
                getter(
                    token,
                    env.stratus.v3 + `subscribers/${subscriber}`)
                .then(result => ({
                    activation: result.activation_date,
                    carrier: result.carrier_config.description,
                    status: result.status
                })) :
                Promise.reject(new Error('invalid-requirements'))))),

    subscriber = () => (
        Promise.all([
            toPromise(token),
            toPromise(info)
        ])
        .then(([token, info]) => {
            const isInternal = info && arrayOwns(info.roles, roles.cam)

            if (!token || !info) {
                return Promise.reject(new Error('invalid-requirements'))
            }

            return (
                isInternal ?
                    {
                        subscriber: null,
                        name: null,
                        email: null,
                        manages: null,
                        account_id: null,
                        role: roles.cam
                    } :
                    query()
                    .then(members => {
                        // billing contact
                        // DEPRECATED: stratus has deprecated /users api
                        if (members.length === 0) {
                            return (
                                Promise.all([
                                    toPromise(token),
                                    toPromise(user)
                                ])
                                .then(([token, user]) => (
                                    getter(token, env.stratus.v3 + `users/${user}`)
                                    .then(result => {
                                        const isBilling = any(result.account_contacts, ({role}) => role === 'Billing')

                                        return (
                                            isBilling ?
                                                {
                                                    subscriber: null,
                                                    name: null,
                                                    email: null,
                                                    account_id: null,
                                                    manages: members,
                                                    role: roles.billing
                                                } :
                                                Promise.reject(
                                                    new ServiceError(
                                                        'no-service-lines',
                                                        'empty')))
                                    })
                                    .catch(error => (
                                        Promise.reject(
                                            error?.response?.status === 404 ?
                                                new ServiceError('no-service-lines') :
                                                error))))))
                        }

                        return (
                            // external cam
                            members.length > 1 ?
                                {
                                    subscriber: null,
                                    name: null,
                                    email: null,
                                    account_id: null,
                                    manages: members,
                                    role: roles.manager
                                } :
                                // single user
                                {
                                    subscriber: members[0].subscriber,
                                    account_id: members[0].account_id,
                                    name: members[0].name,
                                    email: members[0].email,
                                    manages: null,
                                    role: members[0].role
                                })
                    }))
        })),
    deviceStatus = imei => (
        toPromise(token)
        .then(token => (
            token && imei ?
                getter(token, env.ibot.path + `device_status/${imei}`)
                .then(result => result) :
                Promise.reject(new Error('invalid-requirements')))))

export {account, query, subscriber, deviceStatus}
