import {addBreadcrumb, captureMessage} from '@sentry/browser'
import {isDefined, isPlainObject} from '@republic/foundation/lang/is'
import {owns} from '../core/services/array'

const
    severity = ['critical', 'debug', 'error', 'fatal', 'info', 'log', 'warning'],

    log = (message, data, options = {}) => (
        addBreadcrumb({
            category: options.category || 'application',
            level: owns(severity, options.level) ? options.level : 'info',
            message,
            data: (
                data && !isPlainObject(data) ?
                    {data} :
                    data)
        })),

    error = (error, data, options = {}) => {
        const
            message = (
                error instanceof Error ?
                    error.message :
                    error),
            extra = (
                isDefined(data) ?
                    isPlainObject(data) ?
                        data :
                        {data} :
                    null)

        return (
            captureMessage(
                message,
                {
                    ...(extra ? {extra} : {}),
                    ...options
                }))
    }

export {error, log}
