import React, {useEffect} from 'react'
import {keys} from '@republic/foundation/lang/object'
import {createComponent} from '@dash/core/services/component'
import {omit} from '../core/services/object'
import Particle from './Particle'
import {create} from './utils/style-manager'
import {color} from './utils/styles/color'
import {decoration} from './utils/styles/text-decoration'
import {size} from './utils/styles/font-size'
import {weight} from './utils/styles/font-weight'
import {height} from './utils/styles/line-height'
import {align} from './utils/styles/text-align'
import {transform} from './utils/styles/text-transform'

const
    types = {
        className: true,
        tag: true,
        align: true,
        color: true,
        decoration: true,
        leading: true,
        size: true,
        transform: true,
        weight: true
    },

    Type = (
        createComponent(
            'Type',
            {},
            props => {
                const
                    {destroy, hash, style} = (
                        create(
                            'bits-type',
                            props,
                            {
                                ...weight(props.weight),
                                ...align(props.align),
                                ...color(props.color),
                                ...decoration(props.decoration),
                                ...height(props.leading),
                                ...size(props.size),
                                ...transform(props.transform)
                            }))

                useEffect(
                    () => () => destroy(hash),
                    [hash])

                return (
                    <Particle
                        tag={props.tag || 'div'}
                        {...omit(props, keys(types))}
                        {...style} />
                )
            }))

export default Type
