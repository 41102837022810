import React, {useEffect, forwardRef} from 'react'
import {keys} from '@republic/foundation/lang/object'
import {createComponent} from '@dash/core/services/component'
import {omit} from '../core/services/object'
import Particle from './Particle'
import {create} from './utils/style-manager'
import {direction} from './utils/styles/flex-direction'
import {wrap} from './utils/styles/flex-wrap'
import {justify} from './utils/styles/justify-content'
import {align} from './utils/styles/align-items'
import {flex} from './utils/styles/flex'

const
    types = {
        className: true,
        row: true,
        inline: true,
        reverse: true,
        wrap: true,
        justify: true,
        align: true,
        flex: true
    },

    Layout = (
        createComponent(
            'Layout',
            {},
            ({forwardRef, inline, ...props}) => {
                const
                    {destroy, hash, style} = (
                        create(
                            'bits-layout',
                            props,
                            {
                                ...direction(props.row, props.reverse),
                                ...wrap(props.wrap),
                                ...justify(props.justify),
                                ...align(props.align),
                                ...flex(props.flex)
                            }))

                useEffect(
                    () => () => destroy(hash),
                    [hash])

                return (
                    <Particle
                        ref={forwardRef}
                        display={inline ? 'inline-flex' : 'flex'}
                        {...omit(props, keys(types))}
                        {...style} />
                )
            }))

export default forwardRef((props, ref) => (
    <Layout forwardRef={ref} {...props} />
))
