import {useEffect} from 'react'
import {updated} from '@republic/foundation/http/query'
import {origin} from '@republic/foundation/http/urls'
import {createComponent} from '@dash/core/services/component'
import env from '../../env'
import unauthenticating from '../enhancers/unauthenticating'

export default (
    unauthenticating(
        createComponent(
            'LogoutFrame',
            {},
            ({unauthenticating}) => {

                // open auth url
                useEffect(
                    () => {
                        if (unauthenticating) {
                            window.location = (
                                updated(
                                    env.auth.url + env.auth.realm + `logout`,
                                    {post_logout_redirect_uri: origin()}))
                        }
                    },
                    [unauthenticating])

                return null
            })))
