import create from '@dash/core/model'
import {cleanup} from '@dash/core/services/cleanup'
import {destroy} from '@dash/db/storage'
import {subscriber} from './services/subscriber'

const
    {model, methods, cache} = (
        create(
            'subscriber',
            subscriber,

            // This model needs to be last in cleanup and is in charge of nuking the db
            // since it's tied to the subscriber id
            {
                type: 'account',
                retry: error => error.message !== 'no-service-lines',
                priority: 100
            }))

cleanup({
    name: 'database',
    priority: 99,
    fn: () => destroy()
})

export {model, methods, cache}
