import {isValue} from '../is'

const
    alignments = {
        'start': 'flex-start',
        'center': 'center',
        'end': 'flex-end',
        'stretch': 'stretch'
    },

    align = aligned => (
        isValue(aligned) ?
            {alignItems: alignments[aligned]} :
            {})

export {align}
