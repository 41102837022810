import React from 'react'
import {Type} from '@republic/bits'
import {createComponent} from '@dash/core/services/component'
import colors from '@dash/core/colors'

export default (
    createComponent(
        'Unsupported',
        {},
        () => (
            <Type color={colors.white} align={'center'}>
                This browser does not support Dash.<br />
                This can be caused by using a browser's private browsing mode.<br />
                Please switch out of private browsing to continue.
            </Type>
        )))
