import {delayed} from '@republic/foundation/browser/execution'

const
    supported = !!window.requestIdleCallback,

    schedule = fn => (
        supported ?
            window.requestIdleCallback(fn) :
            delayed(fn, 1000)),

    cancel = scheduled => {
        if (scheduled) {
            if (supported) {
                window.cancelIdleCallback(scheduled)
            } else {
                scheduled()
            }
        }
        return null
    }

export {cancel, schedule}
