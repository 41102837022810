import {delayed} from '@republic/foundation/browser/execution'
import {filter, map} from '@republic/foundation/lang/array'
import {isArray, isDefined, isNumber, isFunction, isString} from '@republic/foundation/lang/is'
import {floor} from '@republic/foundation/lang/number'
import {get, owns} from '@republic/foundation/lang/object'
import {number} from '@republic/foundation/lang/random'
import {createNamed} from '@republic/foundation/storage'
import {createMethod, createModel, on} from '@republic/foundation/streams/models'
import {owns as arrayOwns} from '@dash/core/services/array'
import backoff from '@dash/core/services/backoff'
import {cleanup} from '@dash/core/services/cleanup'
import {protect, unprotect} from '@dash/core/services/encrypt'
import local from '@dash/core/services/storage/local'
import session from '@dash/core/services/storage/session'
import query from '@dash/db/query'
import {put, remove, clear as tableClear} from '@dash/db/storage'
import env from '@dash/env'
import focused from '@dash/focus/services/focused'

// options
// nocache: do not store data in storage
// nostream: do not store data in stream
// indexed: true -> indexeddb, false -> session storage
// local: use local storage instead of session. set as a string with a version
// expire: expiration time for storage set in seconds. true picks a random time between 10-20 min
// empty: value to return if storage is undefined
// retry: function to determine if retry is needed
// encrypt: encrypt session storage (not an option for indexed data)

const
    create = (name, service, options = {}) => {
        let errorTimeout = null

        const
            retry = backoff(),

            cache = (
                options.local && isString(options.local) ?
                    createNamed(local, `${options.local}:model:${name}`) :
                    createNamed(session, `${env.version}:model:${name}`)),

            initial = {
                initialized: false,
                fetching: false,
                error: null,
                data: void 0
            },

            // create expiration time
            expire = () => {
                if (owns(options, 'expire')) {
                    const expiration = options.expire

                    if (isNumber(expiration) || expiration === true) {
                        return expiration === true ? (floor(number(10, 20)) * 60) : expiration
                    }
                }
            },

            // private methods
            internal = {
                initialize: createMethod(data => ({data})),
                clear: createMethod(),
                retry: createMethod((error, params) => ({error, params})),
                save: createMethod(data => ({data})),
            },

            // public methods
            // NOTE: put and remove will work without a subscription
            methods = {
                fetch: createMethod((...params) => (params.length ? {params} : {})),
                update: createMethod(fn => ({fn})),
                put: (
                    createMethod(valueOrValues => {
                        put(name, valueOrValues)
                        .then(() => cache.set('indexed', expire()))
                        .catch(() => methods.fetch())
                        return {valueOrValues}
                    })),
                remove: (
                    createMethod(idOrIds => {
                        remove(name, idOrIds)
                        .then(() => cache.set('indexed', expire()))
                        .catch(() => methods.fetch())
                        return {idOrIds}
                    }))
            },

            model = (
                createModel(
                    () => {
                        const
                            cached = (
                                options.encrypt ?
                                    unprotect(cache.get()) :
                                    cache.get())

                        if (!options.indexed || !cached) {
                            internal.initialize(cached)
                        }
                        else {
                            query({table: name})
                            .then(results => (
                                internal.initialize(
                                    results.length ?
                                        results :
                                        void 0)))
                            .catch(() => internal.initialize(void 0))
                        }
                        return initial
                    },

                    on(internal.initialize.stream, (model, {data}) => {
                        if (isDefined(data)) {
                            return {
                                ...model,
                                initialized: true,
                                fetching: false,
                                error: null,
                                data: !options.nostream ? data : true
                            }
                        } else {
                            methods.fetch()
                            return ({
                                ...model,
                                initialized: true,
                                data: owns(options, 'empty') ? options.empty : model.data
                            })
                        }
                    }),

                    on(internal.retry.stream, (model, {error, params}) => {
                        const
                            status = get(error, 'response', 'status'),
                            isInvalid = error.message === 'invalid-requirements',

                            // Never retry on a 403 because it won't succeed.
                            is403 = status && status === 403

                        if (isInvalid || (!is403 && (!options.retry || options.retry(error)))) {
                            errorTimeout = (
                                delayed(
                                    () => (
                                        focused()
                                        .then(() => {
                                            errorTimeout = null
                                            if (params) {
                                                methods.fetch(...params)
                                            } else {
                                                methods.fetch()
                                            }
                                        })),
                                    isInvalid ?
                                        1000 :
                                        retry.get()))
                        }
                        return {
                            ...model,
                            fetching: false,
                            error
                        }
                    }),

                    on(cache.cleared, model => {
                        if (model.initialized) {
                            focused().then(() => methods.fetch())
                        }
                        return model
                    }),

                    on(internal.clear.stream, () => initial),

                    on(internal.save.stream, (model, {data}) => {
                        if (errorTimeout) {
                            errorTimeout()
                            errorTimeout = null
                        }
                        retry.reset()

                        // if incoming data wasn't the previous data
                        // and we are set to cache
                        if (model.data !== data && !options.nocache) {
                            if (options.indexed) {
                                put(name, data)
                                .then(() => cache.set('indexed', expire()))
                                .catch(error => internal.retry(error))
                            } else {
                                cache.set(options.encrypt ? protect(data) : data, expire())
                            }
                        }

                        return {
                            ...model,
                            fetching: false,
                            error: null,
                            data: !options.nostream ? data : true
                        }
                    }),

                    on(methods.fetch.stream, (model, {params}) => {
                        if (model.initialized && service && !model.fetching && !errorTimeout) {

                            Promise.resolve(service(model.data, params))
                            .then(internal.save)
                            .catch(error => internal.retry(error, params))

                            return {...model, fetching: true}
                        } else {
                            return model
                        }
                    }),

                    on(methods.update.stream, (model, {fn}) => {
                        const data = isFunction(fn) ? fn(model.data) : fn

                        if (model.data !== data) {
                            internal.save(data)
                        }
                        return model
                    }),

                    // for indexed data
                    on(methods.put.stream, (model, {valueOrValues}) => {
                        if (isArray(valueOrValues)) {
                            const keys = map(valueOrValues, ({id}) => id)

                            return {
                                ...model,
                                data: [
                                    ...filter(model.data || [], item => !arrayOwns(keys, item.id)),
                                    ...valueOrValues
                                ]
                            }
                        }
                        else {
                            return {
                                ...model,
                                data: [
                                    ...filter(model.data || [], item => item.id !== valueOrValues.id),
                                    valueOrValues
                                ]
                            }
                        }
                    }),

                    // for indexed data
                    on(methods.remove.stream, (model, {idOrIds}) => {
                        if (isArray(idOrIds)) {
                            return {
                                ...model,
                                data: filter(model.data || [], item => !arrayOwns(idOrIds, item.id))
                            }
                        }
                        else {
                            return {
                                ...model,
                                data: filter(model.data || [], item => item.id !== idOrIds)
                            }
                        }
                    })))

        cleanup({
            name,
            priority: options.priority || 10,
            fn: () => {
                internal.clear()
                cache.clear()
                if (options.indexed) {
                    return tableClear(name)
                }
            },
            initialize: internal.initialize
        })

        return {model, methods, cache}
    }

export default create
