import {isNumber} from '@republic/foundation/lang/is'
import {isValue} from '../is'

const
    flex = flexed => (
        isValue(flexed) ?
            {flex: isNumber(flexed) ? flexed : 1} :
            {})

export {flex}
