import React, {lazy, Suspense} from 'react'
import {Layout} from '@republic/bits'
import {compose} from '@republic/foundation/lang/function'
import {createComponent} from '@dash/core/services/component'
import AuthFrames from '../../auth/components/AuthFrames'
import LoginButton from '../../auth/components/LoginButton'
import authenticated from '../../auth/enhancers/authenticated'
import Version from '../../version/components/Version'
import Theme from '../context/Theme'
import useDB from '../hooks/db'
import useResize from '../hooks/resize'
import Center from './Center'
import ErrorBoundary from './ErrorBoundary'
import Loading from './Loading'
import Unsupported from './Unsupported'

import dots from '../../static/svg/relay-dots-background.svg'
import logo from '../../static/svg/relay-pro.svg'

const
    Main = lazy(() => import('./Main')),
    size = width => {
        const breakpoints = {mobile: true, small: true, medium: true}

        if (width >= 1000) {
            breakpoints.mobile = false
        }
        if (width >= 1200) {
            breakpoints.small = false
        }
        if (width >= 1600) {
            breakpoints.medium = false
        }
        return breakpoints
    }

export default (
    compose(
        authenticated
    )(createComponent(
        'App',
        {},
        ({authenticated}) => {
            const
                {width, height} = useResize(),
                {mobile, small, medium} = size(width),
                supported = useDB()

            return (
                <Theme.Provider value={{mobile, small, medium}}>
                    <Layout
                        height={`${height}px`}
                        overflow={'hidden'}
                        position={'relative'}>
                        <Layout
                            position={'absolute'}
                            positions={{top: 6, left: -4}}
                            layer={-1}>
                            <img
                                src={dots}
                                style={{
                                    width: '70em',
                                    transform: 'rotate(27deg) translate(-28em, -8em)'
                                }} />
                        </Layout>
                        {authenticated ?
                            <ErrorBoundary>
                                <Suspense fallback={<Center><Loading /></Center>}>
                                    <Main />
                                </Suspense>
                            </ErrorBoundary> :
                            <Layout align={'center'} justify={'center'} height={'100%'}>
                                <Layout margin={{bottom: 2}}>
                                    <img style={{width: '16em'}} src={logo} alt={'Relay Dash'} />
                                </Layout>
                                {supported ?
                                    <LoginButton /> :
                                    <Unsupported />}
                            </Layout>}
                    </Layout>
                    <AuthFrames />
                    <Version authenticated={authenticated} />
                    <div id={'backdrop'} />
                </Theme.Provider>
            )
        })))
