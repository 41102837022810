import {isPlainObject, isString, isArray} from '@republic/foundation/lang/is'
import {bordering} from '../css'
import {isValue} from '../is'

const
    STYLE = 'solid',
    COLOR = '#D4D4D4',
    WIDTH = 1,

    border = value => (
        isValue(value) ?
            isPlainObject(value) ?
                {
                    ...bordering(value.style || STYLE, 'style'),
                    ...bordering(value.width || WIDTH, 'width', 'px'),
                    ...bordering(value.color || COLOR, 'color')
                } :
                isString(value) ?
                    {border: value} :
                    {
                        borderStyle: STYLE,
                        borderColor: COLOR,
                        ...bordering(isArray(value) ? value : 1, 'width', 'px')
                    } :
            {})

export {border}
