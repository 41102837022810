import {isFunction} from '@republic/foundation/lang/is'

const
    createComponent = (
        (name, _, definition) => (
            Object.assign(
                isFunction(definition) ? definition : () => definition,
                {
                    displayName: name
                })))

export {
    createComponent
}