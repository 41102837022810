import React from 'react'
import {createComponent} from '@dash/core/services/component'
import Icon from './Icon'

import white from '../../static/svg/sync-white.svg'
import black from '../../static/svg/sync.svg'

export default (
    createComponent(
        'SyncIcon',
        {},
        props => (
            <Icon white={white} black={black} {...props} />
        )))
