import {map} from '@republic/foundation/lang/array'
import {uuid} from '@republic/foundation/lang/random'
import Worker from './database-worker'

const
    handler = (message, cb, eb) => {
        const {data, error} = message.data

        if (error) {
            eb(error)
        } else {
            cb(data)
        }
    },

    transmit = (worker, request, cb) => {
        const
            message = payload => {
                handler(payload, request.resolve, request.reject)
                worker.removeEventListener('message', message)
                cb()
            }

        worker.addEventListener('message', message)
        worker.postMessage(request.config)
    }

class Pool {
    constructor(name) {
        this.name = name
        this.queue = new Map()
        this.pool = (
            new Map(
                map(new Array(4), (_, i) => ([i, {
                    busy: false,
                    worker: new Worker()
                }]))))
    }
    allocate = () => {
        if (this.queue.size) {
            this.pool.forEach(({busy, worker}, poolId) => {
                if (!busy && this.queue.size) {
                    const [requestId, request] = this.queue.entries().next().value

                    this.queue.delete(requestId)
                    this.pool.set(poolId, {busy: true, worker})

                    transmit(
                        worker,
                        request,
                        () => {
                            this.pool.set(poolId, {busy: false, worker})
                            this.allocate()
                        })
                }
            })
        }
    }
    request = config => (
        new Promise((resolve, reject) => {
            this.queue.set(
                uuid(),
                {config, resolve, reject})
            this.allocate()
        }))
}

export default Pool