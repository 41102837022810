import {decrypt} from 'crypto-js/aes'
import Utf8 from 'crypto-js/enc-utf8'
import hash from '@dash/env/hash'
import mix from './mix'

const
    unpack = message => (
        JSON.parse(
            decrypt(message, mix(hash))
            .toString(Utf8)))

export default unpack