import {filter, reduce} from '@republic/foundation/lang/array'
import {identity} from '@republic/foundation/lang/function'
import {keys, owns} from '@republic/foundation/lang/object'
import {title} from '@republic/foundation/lang/string'
import {isPlainObject, isNumber, isArray, isString} from '@republic/foundation/lang/is'
import {isValue} from '../is'

const
    edges = ['top', 'right', 'bottom', 'left'],
    corners = ['top-left', 'top-right', 'bottom-right', 'bottom-left'],

    // returns a longhand object from a possible shorthand value
    toLonghand = (value, positions) => {

        // make obj of same values
        if (isNumber(value)) {
            return (
                reduce(
                    positions,
                    (memo, position) => {
                        memo[position] = value
                        return memo
                    },
                    {}))
        }

        // make array and recurse
        else if (isString(value)) {
            return (
                toLonghand(
                    filter(value.split(' '), identity),
                    positions))
        }

        // make obj
        else if (isArray(value)) {
            return (
                reduce(
                    positions,
                    (memo, position, i) => {
                        if (i === 0) {
                            memo[position] = value[0]
                        }
                        else if (i === 1) {
                            memo[position] = isValue(value[1]) ? value[1] : value[0]
                        }
                        else if (i === 2) {
                            memo[position] = isValue(value[2]) ? value[2] : value[0]
                        }
                        else if (i === 3) {
                            memo[position] = isValue(value[3]) ? value[3] : isValue(value[1]) ? value[1] : value[0]
                        }
                        return memo
                    },
                    {}))
        }

        // make number and recurse
        else if (value === true) {
            return toLonghand(1, positions)
        }

        // return only longhand values
        else if (isPlainObject(value)) {
            return (
                reduce(
                    positions,
                    (memo, position) => {
                        if (owns(value, position)) {
                            memo[position] = value[position]
                        }
                        return memo
                    },
                    {}))
        }

        else {
            return {}
        }
    },

    toValue = (value, unit = 'em') => {
        if (isNumber(value)) {
            value = `${value}${unit}`
        }
        if (value === true) {
            value = `1${unit}`
        }
        return value
    },

    // makes a JS CSS object from a longhand object
    longhandToStyle = (obj, prefix, suffix, unit) => (
        reduce(
            keys(obj),
            (memo, position) => {
                const
                    start = prefix || '',
                    middle = (
                        prefix ?
                            position.split('-').map(title).join('') :
                            position),
                    end = suffix ? title(suffix) : ''

                memo[`${start}${middle}${end}`] = toValue(obj[position], unit)
                return memo
            },
            {})),

    spacing = (name, value) => longhandToStyle(toLonghand(value, edges), name),
    positioning = value => longhandToStyle(toLonghand(value, edges)),
    cornering = value => longhandToStyle(toLonghand(value, corners), 'border', 'radius'),
    bordering = (value, type, unit) => longhandToStyle(toLonghand(value, edges), 'border', type, unit)

export {
    bordering,
    cornering,
    positioning,
    spacing,
    toValue
}
