import {Source} from '@republic/foundation/streams'
import {noop} from '@republic/foundation/lang/function'

const
    source = new Source(true),
    update = () => source.next(window.navigator.onLine),
    online = source.latest()

window.addEventListener('online', update)
window.addEventListener('offline', update)

online.subscribe(noop)
update()

export {online}
