const
    roles = {
        admin: 'relay_account_admin',
        user: 'relay_account_user',
        limited: 'relay_comms_user',
        manager: 'relay_account_manager',
        cam: 'internal_support_user',
        billing: 'billing'
    },

    whoami = {
        privileged: role => role === roles.admin || role === roles.manager || role === roles.cam,
        anycam: role => role === roles.manager || role === roles.cam
    },

    labels = {
        [roles.admin]: 'Administrator',
        [roles.user]: 'User',
        [roles.limited]: 'Limited',
        [roles.manager]: 'Account Manager',
        [roles.cam]: 'Internal Support',
        [roles.billing]: 'Billing'
    }

export {labels, roles, whoami}
