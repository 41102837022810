import {isString, isPlainObject} from '@republic/foundation/lang/is'
import pack from './pack'
import unpack from './unpack'

const
    protect = value => {
        const
            type = isString(value) ? 'string' : 'json',
            serialized = isString(value) ? value : JSON.stringify(value),
            encrypted = pack(serialized)

        return {version: 'v1', data: encrypted, type}
    },

    unprotect = value => {
        if (isPlainObject(value) && value.version === 'v1') {
            const decrypted = unpack(value.data)

            return (
                value.type === 'json' ?
                    JSON.parse(decrypted) :
                    decrypted)
        } else {
            return value
        }
    }

export {
    protect,
    unprotect
}