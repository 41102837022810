const
    create = name => {
        const
            tabs = window.BroadcastChannel && new BroadcastChannel('tabs'),

            subscribe = fn => {
                const
                    handler = ({data}) => {
                        const parsed = JSON.parse(data)

                        if (parsed.name === name) {
                            fn(parsed.data)
                        }
                    }

                tabs?.addEventListener('message', handler)
                return () => (
                    tabs?.removeEventListener('message', handler))
            },

            send = data => (
                tabs?.postMessage(
                    JSON.stringify({name, data})))

        return {subscribe, send}
    }

export default create
