import {online} from '../streams'

const
    isOnline = () => (
        new Promise(resolve => {
            const
                subscription = (
                    online.subscribe(online => {
                        if (online) {
                            subscription()
                            resolve()
                        }
                    }))
        }))

export default isOnline
