import {noop} from '@republic/foundation/lang/function'
import {cleanup} from '@dash/core/services/cleanup'
import {schedule, cancel} from '@dash/core/services/idle'
import {put} from '@dash/db/storage'
import format from './format'

let
    queue = [],
    scheduled = null,
    busy = false

const
    onIdle = () => {
        cancel(scheduled)
        scheduled = schedule(process)
    },

    process = () => {
        if (queue.length && !busy) {
            const next = [...queue]

            busy = true
            queue = []

            put('logs', next)
            .catch(noop)
            .finally(() => busy = false)
        }
    },

    store = breadcrumb => {
        const formatted = format(breadcrumb)

        queue.push(formatted)
        onIdle()
        return formatted
    }

cleanup({
    name: 'breadcrumb-queue',
    fn: () => queue = []
})

export {store, process}
