import {subscribe} from '@republic/bits'
import {authenticating} from '../streams'
import {methods} from '../methods'

export default (
    subscribe(
        null,
        () => authenticating,
        ({csrf, enabled, verifier}, props) => ({
            ...props,
            authenticating: {
                authorized: methods.login.authenticating,
                begin: methods.login.begin,
                cancel: methods.login.cancel,
                csrf,
                enabled,
                verifier
            }
        })))
