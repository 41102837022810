import {isValue} from '../is'

const
    justifications = {
        'start': 'flex-start',
        'center': 'center',
        'end': 'flex-end',
        'around': 'space-around',
        'between': 'space-between'
    },

    justify = justified => (
        isValue(justified) ?
            {justifyContent: justifications[justified]} :
            {})

export {justify}
