const direction = (row, reverse) => ({
    flexDirection: (
        row ?
            reverse ?
                'row-reverse' :
                'row' :
            reverse ?
                'column-reverse' :
                'column')
})

export {direction}
