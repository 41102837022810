import {useEffect} from 'react'
import {createComponent} from '@dash/core/services/component'
import {compose} from '@republic/foundation/lang/function'
import subscriber from '@dash/subscriber/enhancers/subscriber'
import version from '../enhancers/version'
import {methods} from '../model'

export default (
    compose(
        subscriber,
        version
    )(createComponent(
        'Version',
        {},
        ({authenticated, subscriber, version}) => {
            const
                update = () => {
                    window?.sessionStorage?.clear()
                    window.location = window.location.origin
                }

            useEffect(
                () => {
                    if (subscriber) {
                        methods.fetch()
                    }
                },
                [subscriber])

            useEffect(
                () => {
                    if (version?.urgent) {
                        update()
                    }
                },
                [version?.urgent])

            useEffect(
                () => {
                    if (version?.update && !authenticated) {
                        update()
                    }
                },
                [version?.update, authenticated])

            return null
        })))
