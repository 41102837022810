const
    INITIAL = 2500,
    MAX = 30000,

    backoff = () => {
        let count = INITIAL

        return {
            get: () => {
                const wait = count

                if (count < MAX) {
                    count = Math.floor(count * (1.5 + Math.random()))
                }

                if (count > MAX) {
                    count = MAX
                }

                return wait
            },
            reset: () => count = INITIAL
        }
    }

export default backoff
