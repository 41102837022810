import {map, reduce} from '@republic/foundation/lang/array'
import {isArray, isPlainObject, isString} from '@republic/foundation/lang/is'
import {keys, owns} from '@republic/foundation/lang/object'
import {string} from '@republic/foundation/lang/random'

const
    obfuscate = {
        password: true,
        token: true,
        access_token: true,
        refresh_token: true,
        code_verifier: true,
        encryption_key: true
    },

    create = ({category, level, data, timestamp, message}) => {
        let safe
        const
            breadcrumb = {
                id: `${timestamp}/${string(6)}`,
                timestamp,
                category,
                level,
                message,
                data
            }

        try {
            safe = JSON.parse(JSON.stringify(breadcrumb))
        } catch (error) {
            console.log(error, breadcrumb)
            safe = {
                ...breadcrumb,
                data: 'Data could not be serialized'
            }
        }

        return safe
    },

    regex = (
        new RegExp(
            reduce(
                keys(obfuscate),
                (memo, key, i) => (
                    `${memo}${i === 0 ? '' : '|'}(^.*${key}.*$)`),
                ''),
            'ig')),

    scrub = value => (
        isPlainObject(value) ?
            reduce(
                keys(value),
                (memo, key) => {
                    if (key === 'data' && value.content_type === 'application/json') {
                        memo.data = scrub(JSON.parse(value.data))
                    } else {
                        memo[key] = (
                            owns(obfuscate, key) ?
                                'REDACTED' :
                                scrub(value[key]))
                    }
                    return memo
                },
                {}) :
            isArray(value) ?
                map(value, scrub) :
                isString(value) ?
                    regex.test(value) ?
                        'REDACTED' :
                        value :
                    value),

    message = breadcrumb => (
        isString(breadcrumb.message) ?
            breadcrumb.category === 'fetch' ?
                `[${breadcrumb.data.method}] [${breadcrumb.data.status_code}] ${breadcrumb.data.url}` :
                breadcrumb.message :
            'Unknown breadcrumb'),

    category = breadcrumb => (
        breadcrumb.category === 'sentry.event' ?
            'error' :
            breadcrumb.category),

    format = breadcrumb => (
        create({
            ...breadcrumb,
            category: category(breadcrumb),
            message: message(breadcrumb),
            data: scrub(breadcrumb.data)
        }))

export default format
