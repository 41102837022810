import {subscribe} from '@republic/bits'
import {authenticated} from '../streams'

export default (
    subscribe(
        null,
        () => authenticated,
        (authenticated, props) => ({
            ...props,
            authenticated
        })))
