import {get} from '@republic/foundation/lang/object'
import {init, setContext, setUser, showReportDialog} from '@sentry/browser'
import {info} from '@dash/auth/streams'
import {owns} from '@dash/core/services/array'
import {cast} from '@dash/core/services/date'
import {omit} from '@dash/core/services/object'
import {focus} from '@dash/focus/streams'
import env from '@dash/env'
import {log} from './log'
import {process, store} from './services/storage'

const
    initialize = () => {
        const
            ignore = ['xhr', 'ui.click', 'ui.input', 'console', 'navigation', 'sentry.event', 'fetch', ...(env.env === 'prod' ? ['devmon'] : [])]

        init({
            dsn: env.sentry.dsn,
            release: `dash@${env.version}`,
            environment: env.env,
            autoSessionTracking: false,
            ignoreErrors: [
                'ResizeObserver loop limit exceeded',
                'ResizeObserver loop completed with undelivered notifications.',
                "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing."
            ],
            beforeSend: (event, hint) => {
                const message = get(hint, 'originalException', 'message') || hint.originalException

                log(
                    `${message || 'Unknown Error'} [${hint.event_id}]`,
                    get(hint, 'captureContext', 'extra'),
                    {category: 'error'})

                if (message === 'feedback-modal' && env.env !== 'prod') {
                    showReportDialog({
                        title: 'Something is wrong',
                        subtitle: 'Please leave a description of what you were doing so engineering can troubleshoot.',
                        subtitle2: ''
                    })
                }

                return (
                    env.env === 'dev' ?
                        null :
                        event)
            },
            beforeBreadcrumb: (breadcrumb, _hint) => {
                if (!owns(ignore, breadcrumb.category)) {
                    const
                        stored = (
                            store({
                                ...breadcrumb,
                                timestamp: new Date().toISOString()
                            }))

                    // log uploads are too big for sentry
                    if (stored.category === 'request' && stored.message.includes('relay_app_logs')) {
                        return null
                    }

                    // devmon data just makes Sentry hard to read so remove it
                    if (stored.category === 'devmon') {
                        return null
                    }

                    return omit(stored, 'id')
                } else {
                    return null
                }
            }
        })

        setContext('app', {
            app_start_time: cast().toISO()
        })

        log(`Dash started: ${env.env} v${env.version}`)

        // unload event
        window.addEventListener('beforeunload', () => {
            log('Dash shutdown')
            process()
        })

        // online/offline
        window.addEventListener('online', () => log('Dash online'))
        window.addEventListener('offline', () => log('Dash offline'))

        // tab focus
        focus.subscribe(visible => (
            log(visible ? 'Dash focused' : 'Dash unfocused')))

        // user info
        info.subscribe(info => {
            if (info) {
                setUser({
                    id: info.user,
                    username: `${info.first_name} ${info.last_name}`,
                    email: info.email
                })
            } else {
                setUser(null)
            }
        })
    }

export default initialize