import React from 'react'
import {createComponent} from '@dash/core/services/component'
import Button from './Button'
import SyncIcon from './SyncIcon'

export default (
    createComponent(
        'Loading',
        {},
        props => (
            <Button
                size={'xl'}
                rotate
                Icon={SyncIcon}
                label={'Loading'}
                {...props} />
        )))
