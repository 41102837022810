import {subscribe} from '@republic/bits'
import {unauthenticating} from '../streams'

export default (
    subscribe(
        null,
        () => unauthenticating,
        (unauthenticating, props) => ({
            ...props,
            unauthenticating
        })))
