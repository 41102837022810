import {focus} from '../streams'

const
    focused = () => (
        new Promise(resolve => {
            const
                subscription = (
                    focus.subscribe(focused => {
                        if (focused) {
                            subscription()
                            resolve()
                        }
                    }))
        }))

export default focused
