/* global VERSION ENVIRONMENT */

import unpack from '@dash/core/services/unpack'

// set from webpack plugin...
const
    version = VERSION,
    environment = ENVIRONMENT,
    env = {
        version,
        ...unpack(environment)
    }

export default env
