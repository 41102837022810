import React from 'react'
import {Layout} from '@republic/bits'
import {createComponent} from '@dash/core/services/component'

export default (
    createComponent(
        'Icon',
        {},
        ({invert, width = 1.5, white, black, ...props}) => (
            <Layout width={width} {...props}>
                <img src={invert ? white : black} />
            </Layout>
        )))
