import {decode} from '@republic/foundation/http/query'
import {map} from '@republic/foundation/lang/array'
import {isString} from '@republic/foundation/lang/is'

const
    resources = {
        device: 'devices',
        group: 'groups',
        tag: 'tags'
    },

    parse = (urn, asList) => {
        if (isString(urn) && urn.startsWith('urn')) {
            const
                segments = urn.split(','),
                mapped = (
                    map(segments, segment => {
                        const
                            [_, __, type, resource, valueAndQuery] = segment.split(':'),
                            [value, query] = (valueAndQuery || '').split('?')

                        return {
                            type,
                            table: resources[resource],
                            value,
                            query: decode(query)
                        }
                    }))

            return asList ? mapped : mapped[0]
        } else {
            return null
        }
    }

export {parse}