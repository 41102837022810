import {useEffect} from 'react'
import Base64 from 'crypto-js/enc-base64'
import sha256 from 'crypto-js/sha256'
import {decode, updated} from '@republic/foundation/http/query'
import {origin} from '@republic/foundation/http/urls'
import {createComponent} from '@dash/core/services/component'
import {pick} from '@dash/core/services/object'
import env from '@dash/env'
import authenticating from '../enhancers/authenticating'
import {methods} from '../methods'
import {cache} from '../model'

const
    safe = str => (
        str
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '')),

    hash = string => sha256(string),

    sanitized = query => pick(query, ['subscriber'])

export default (
    authenticating(
        createComponent(
            'LoginFrame',
            {},
            ({authenticating: {authorized, csrf, enabled, verifier}}) => {
                const
                    cached = cache.redirect.get() || {},
                    incoming = decode(window.location.search.slice(1))

                // auto redirect if identity provider is set
                useEffect(
                    () => {
                        if (!enabled && incoming.idp) {
                            methods.login.begin()
                        }
                    },
                    [enabled, incoming.idp])

                // handle redirect
                useEffect(
                    () => {
                        if (cached.csrf && incoming.state && cached.csrf === incoming.state) {
                            window.history.replaceState({}, '', updated(origin(), cached.incoming || {}))
                            cache.redirect.clear()
                            authorized(incoming.code, cached.verifier)
                        }
                    },
                    [cached.csrf, cached.verifier, incoming.state, incoming.code])

                // open auth url
                useEffect(
                    () => {
                        if (enabled) {
                            cache.redirect.set({csrf, verifier, incoming: sanitized(incoming)})
                            window.location = (
                                updated(
                                    env.auth.url + env.auth.realm + 'auth',
                                    {
                                        response_type: 'code',
                                        client_id: env.auth.client,
                                        redirect_uri: origin(),
                                        state: csrf,
                                        code_challenge: safe(hash(verifier).toString(Base64)),
                                        code_challenge_method: 'S256',
                                        scope: 'offline_access',
                                        ...(incoming.idp ? {kc_idp_hint: incoming.idp} : {})
                                    }))
                        }
                    },
                    [enabled])

                return null
            })))
