import {encode} from '@republic/foundation/http/query'
import {map} from '@republic/foundation/lang/array'
import {origin} from '@republic/foundation/http/urls'
import {cast} from '../../core/services/date'
import {poster} from '../../core/services/request'
import env from '../../env'

const
    headers = () => ({
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    }),

    decode = token => {
        const
            base64Url = token.split('.')[1],
            base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'),
            jsonPayload = (
                decodeURIComponent(
                    map(
                        window.atob(base64).split(''),
                        c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join('')))

        return JSON.parse(jsonPayload)
    },

    verify = ({access_token, refresh_token, expires_in}) => (
        Promise.resolve(decode(access_token))
        .then(data => ({
            token: access_token,
            refresh: refresh_token,
            expires: expires_in,
            info: {
                first_name: data.given_name,
                last_name: data.family_name,
                email: data.email,
                user: data.sub,
                sso: data.is_sso,
                expiration: cast().plus({seconds: expires_in}).toISO(),
                roles: data.realm_access.roles
            }
        }))),

    refresher = refresh => (
        poster(
            null,
            `${env.auth.url}${env.auth.realm}token`,
            encode({
                client_id: env.auth.client,
                grant_type: 'refresh_token',
                refresh_token: refresh
            }),
            {
                headers: headers(),
                timeout: 5000
            })
        .then(verify)),

    authorization = (code, verifier) => (
        poster(
            null,
            `${env.auth.url}${env.auth.realm}token`,
            encode({
                client_id: env.auth.client,
                grant_type: 'authorization_code',
                code_verifier: verifier,
                code,
                redirect_uri: origin()
            }),
            {headers: headers()})
        .then(verify))

export {
    authorization,
    refresher
}
