import create from '@dash/core/model'
import {fetch} from './services/version'

const
    {methods, model} = (
        create(
            'version',
            fetch,
            {expire: 5 * 60}))

export {methods, model}
