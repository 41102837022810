import {toValue} from '../css'
import {isValue} from '../is'

const
    height = value => (
        isValue(value) ?
            {lineHeight: toValue(value)} :
            {})

export {height}
