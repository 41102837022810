import React from 'react'
import {createComponent} from '@dash/core/services/component'
import LoginFrame from './LoginFrame'
import LogoutFrame from './LogoutFrame'

export default (
    createComponent(
        'AuthFrames',
        {},
        () => (
            <React.Fragment>
                <LoginFrame />
                <LogoutFrame />
            </React.Fragment>
        )))
