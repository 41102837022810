import {useState, useEffect} from 'react'
import {delayed} from '@republic/foundation/browser/execution'

const
    size = () => {
        let timeout = null
        const [size, setSize] = useState({width: window.innerWidth, height: window.innerHeight})

        useEffect(
            () => {
                const
                    resize = () => {
                        if (timeout) {
                            timeout()
                        }
                        timeout = (
                            delayed(
                                () => setSize({
                                    width: window.innerWidth,
                                    height: window.innerHeight
                                }),
                                100))
                    }

                window.addEventListener('resize', resize)
                return () => {
                    if (timeout) {
                        timeout()
                    }
                    window.removeEventListener('resize', resize)
                }
            },
            [])

        return size
    }

export default size
