import {toValue} from '../css'
import {isValue} from '../is'

const
    dimensions = (type, value) => (
        isValue(value) ?
            {[type]: toValue(value)} :
            {})

export {dimensions}
