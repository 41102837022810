import {DateTime, Duration, Info} from 'luxon'
import {reduce} from '@republic/foundation/lang/array'
import {isNumber, isString} from '@republic/foundation/lang/is'
import {abs} from '@republic/foundation/lang/number'

const
    now = () => (new Date()).getTime(),

    cast = timestamp => (
        !timestamp ?
            DateTime.now() :
            timestamp instanceof Date ?
                DateTime.fromJSDate(timestamp) :
                DateTime.isDateTime(timestamp) ?
                    timestamp :
                    DateTime.fromISO(timestamp)),

    // create a timestamp 1 millisecond later
    advance = timestamp => {
        const
            a = isString(timestamp) && timestamp.includes('.') && timestamp.split('.'),
            b = a && isString(a[1]) && a[1].includes('Z') && a[1].split('Z'),
            milli = b && isString(b[0]) && parseInt(b[0])

        return (
            isNumber(milli) ?
                [a[0], '.', String(milli + 1).padStart(3, '0'), 'Z'].join('') :
                cast(timestamp).plus({millisecond: 1}).toUTC().toISO())
    },

    toJS = timestamp => (
        cast(timestamp).toJSDate()),

    format = (timestamp, format) => {
        const
            now = cast(),
            date = cast(timestamp),
            day = (
                date.toFormat(
                    now.toFormat('y') !== date.toFormat('y') ?
                        `cccc, LLLL d, y` :
                        `cccc, LLLL d`)),
            time = date.toFormat(`h:mm:ss a`)

        return (
            format ?
                date.toFormat(format) :
                `${day} at ${time}`
        )
    },

    plural = (value, unit) => (
        `${value} ${unit}${value === 1 ? '' : 's'}`),

    zero = (time, unit) => {
        const value = time[unit + 's']

        return (
            value !== 0 ?
                ` and ${plural(value, unit)}` :
                '')
    },

    range = (quantity = 7, unit = 'days', o, n) => {
        const
            oldest = o && cast(o).toUTC().toISO(),
            newest = n && cast(n).toUTC().toISO()

        return (
            reduce(
                new Array(quantity),
                (memo, _, i) => {
                    let
                        end = cast().toUTC().endOf(unit.slice(0, -1)).minus({[unit]: i}).toISO(),
                        start = cast().toUTC().startOf(unit.slice(0, -1)).minus({[unit]: i}).toISO()

                    if (oldest && start <= oldest) {
                        start = oldest
                    }
                    if (newest && end >= newest) {
                        end = newest
                    }

                    return (
                        (!oldest || end > oldest) && (!newest || start < newest) ?
                            [...memo, [start, end]] :
                            memo)
                },
                []))
    },

    paginate = (oldest, newest, set = []) => {
        const nextOldest = cast(newest).toUTC().minus({days: 7}).toISO()

        if (!isString(oldest) || !isString(newest)) {
            throw new Error('Paginate called without date string')
        }
        return (
            nextOldest < oldest ?
                [...set, {oldest, newest}] :
                paginate(oldest, nextOldest, [...set, {oldest: nextOldest, newest}]))
    },

    minutes = timestamp => {
        const elapsed = now() - new Date(timestamp).getTime()

        return Duration.fromMillis(elapsed).shiftTo('minutes').toObject().minutes
    },

    getDuration = (a, b) => (
        abs(new Date(a).getTime() - new Date(b).getTime())),

    duration = timestamp => getDuration(now(), timestamp),

    ago = timestamp => (
        elapsed(duration(timestamp), true)),

    elapsed = (duration, rounded) => {
        const
            time = (
                Duration
                .fromMillis(duration)
                .shiftTo('days', 'hours', 'minutes', 'seconds', 'milliseconds')
                .toObject())

        return (
            time.days ?
                `${plural(time.days, 'day')}${!rounded ? zero(time, 'hour') : ''}` :
                time.hours ?
                    `${plural(time.hours, 'hour')}${!rounded ? zero(time, 'minute') : ''}` :
                    time.minutes ?
                        `${plural(time.minutes, 'minute')}${!rounded ? zero(time, 'second') : ''}` :
                        rounded ?
                            time.seconds >= 30 ?
                                'Less than a minute' :
                                'A few seconds' :
                            time.seconds >= 1 ?
                                plural(time.seconds, 'second') :
                                'Less than a second')
    }

export {
    advance,
    ago,
    duration,
    getDuration,
    cast,
    toJS,
    elapsed,
    format,
    now,
    minutes,
    paginate,
    DateTime,
    Duration,
    Info,
    range
}
