import {createNamed} from '@republic/foundation/storage'
import {cleanup} from '@dash/core/services/cleanup'
import session from '@dash/core/services/storage/session'

const storage = createNamed(session, 'mode')

cleanup({
    name: 'mode',
    fn: () => storage.clear()
})

export {storage}
