import {all, index, reduce} from '@republic/foundation/lang/array'
import {stringify} from '@republic/foundation/lang/json'
import {keys, owns} from '@republic/foundation/lang/object'

let counter = 'aa'

const
    sheet = window.document.createElement('style'),

    hashes = {},

    // https://stackoverflow.com/a/30686425/1078392
    increment = () => {
        counter = ((parseInt(counter, 36) + 1).toString(36)).replace(/0/g, 'a')

        if (all(counter.split(''), char => char === 'z')) {
            counter = counter.replace(/z/g, 'a')
            counter += 'a'
        }
    },

    toDash = string => string.replace(/([A-Z])/g, $1 => "-" + $1.toLowerCase()),

    makeHash = obj => (
        keys(obj).length > 0 ?
            stringify(obj) :
            null),

    makeRule = (name, hash, styles) => {
        if (hash) {
            if (!owns(hashes, hash)) {
                const
                    className = `${name}-${counter}`,
                    style = (
                        reduce(
                            keys(styles),
                            (memo, key) => {
                                memo += `${toDash(key)}:${styles[key]};`
                                return memo
                            },
                            ''))

                sheet.sheet.insertRule(`.${className} {${style}}`)
                hashes[hash] = {styles, count: 1, className}
                increment()
            } else {
                hashes[hash].count += 1
            }
            return hashes[hash].className
        }
    },

    destroy = hash => {
        if (hash && owns(hashes, hash)) {
            if (hashes[hash].count === 1) {
                sheet.sheet.deleteRule(
                    index(
                        sheet.sheet.cssRules,
                        rule => (
                            rule.selectorText === `.${hashes[hash].className}`)))
                delete hashes[hash]
            } else {
                hashes[hash].count -= 1
            }
        }
    },

    create = (name, props, styles) => {
        const
            cascade = props.className,
            hash = sheet && makeHash(styles),
            className = makeRule(name, hash, styles)

        return {
            destroy,
            hash,
            style: {
                className: (
                    className ?
                        cascade ?
                            [cascade].concat([className]).join(' ') :
                            className :
                        cascade)
            }
        }
    }

sheet.id = 'style-manager'
document.head.appendChild(sheet)
sheet.sheet.insertRule(`body {margin: 0;}`)
sheet.sheet.insertRule(`img {max-width: 100%;}`)

export {create}
