import {reduce} from '@republic/foundation/lang/array'
import {isEqual} from '@republic/foundation/lang/is'
import {model} from './model'

const
    // NOTE: the model...
    // manages: null || []
    // role: 'relay_account_admin'
    // subscriber: 'ae0f3ba5-e35e-421a-98e2-e9ae93136997',
    // name: 'Account Name',
    // email: 'owner@email.com'

    // ibot routing header hash
    hash = str => {
        const
            m = (
                reduce(
                    [...str.slice(0)],
                    (sum, char) => {
                        const value = parseInt(char, 16)

                        return Number.isInteger(value) ? (sum + value) : sum
                    },
                    0) % 25)

        return `${(10 + Math.floor(m / 5)).toString(16).toUpperCase()}${1 + (m % 5)}`
    },

    data = (
        model
        .map(({data}) => data || {})
        .latest()),

    subscriber = (
        data.map(({subscriber}) => subscriber)
        .unique(isEqual)
        .latest()),

    hint = (
        subscriber.map(
            subscriber => (
                subscriber ?
                    hash(subscriber) :
                    null))
        .unique(isEqual)
        .latest()),

    role = (
        data.map(({role}) => role)
        .unique(isEqual)
        .latest()),

    manages = (
        data.map(({manages}) => manages)
        .unique(isEqual)
        .latest()),

    fetching = (
        model.map(({fetching}) => fetching)
        .unique(isEqual)
        .latest()),

    error = (
        model.map(({error}) => error)
        .unique(isEqual)
        .latest()),

    account = (
        data.map(({name, email, subscriber, account_id}) => ({name, email, subscriber, account_id}))
        .unique(isEqual)
        .latest())

export {account, error, fetching, hint, manages, subscriber, role}
